body {
    overflow: hidden;
}

.editor {
    border: 1px solid silver;
    margin: 0.3em;
    height: calc(100% - 0.6em);
}

#root {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

    #root > div {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
        min-width: 100px;
    }

.resultPane {
    overflow-y: scroll;
    height: 100%;
}

.tabbedSection {
    display: flex;
    flex-direction: column;
}

    .tabbedSection > ul {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .tabbedSection > .tab-content {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        min-height: 1px;
    }

        .tabbedSection > .tab-content > .tab-pane {
            height: 100%;
        }

.diffstatus.same {
    color: forestgreen;
}

.diffstatus.different {
    color: firebrick;
}
